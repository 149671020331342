// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count_not_readed_messages_item__gcKni {
  width: 28px;
  height: 28px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 7px;
  bottom: 12px;
  color: #fff;
  background-color: #636DEE;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/count_not_readed_messages/count_not_readed_messages.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;AACJ","sourcesContent":[".item{\r\n    width: 28px;\r\n    height: 28px;\r\n    display: flex;\r\n    font-size: 12px;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: absolute;\r\n    right: 7px;\r\n    bottom: 12px;\r\n    color: #fff;\r\n    background-color: #636DEE;\r\n    border-radius: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `count_not_readed_messages_item__gcKni`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label_input_item__DGSJV {
  display: flex;
  align-items: center;
}

.label_input_label__nxgnt {
  font-weight: bold;
}

.label_input_input__IASxp {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 1px 1px 5.9000000954px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/label_input/label_input.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,oCAAA;EACA,kBAAA;EACA,sDAAA;EACA,sBAAA;AACJ;;AAEA;;EAEI,wBAAA;EACA,SAAA;AACJ","sourcesContent":[".item {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.label {\r\n    font-weight: bold;\r\n}\r\n\r\n.input {\r\n    font-weight: bold;\r\n    border: 1px solid rgba(0, 0, 0, 0.2);\r\n    border-radius: 5px;\r\n    box-shadow: 1px 1px 5.900000095367432px rgba(0, 0, 0, 0.15);\r\n    box-sizing: border-box;\r\n}\r\n\r\ninput[type=number]::-webkit-inner-spin-button,\r\ninput[type=number]::-webkit-outer-spin-button {\r\n    -webkit-appearance: none;\r\n    margin: 0;\r\n}\r\n\r\n// input[type=number]::-webkit-outer-spin-button,\r\n// input[type=number]::-webkit-inner-spin-button {\r\n//     -webkit-appearance: inner-spin-button !important;\r\n//     width: 25px;\r\n//     position: absolute;\r\n//     top: 50%;\r\n//     transform: translateY(-50%);\r\n//     right: 2px;\r\n//     height: 60%;\r\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `label_input_item__DGSJV`,
	"label": `label_input_label__nxgnt`,
	"input": `label_input_input__IASxp`
};
export default ___CSS_LOADER_EXPORT___;

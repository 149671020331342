// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_content__bq2tU {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100vh - 100px);
  padding-bottom: 20px;
}

.content_messages__ahXzk {
  overflow-y: hidden;
}
.content_messages__ahXzk::-webkit-scrollbar {
  width: 7px;
}
.content_messages__ahXzk::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 5px;
}
.content_messages__ahXzk:hover {
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,2BAAA;EACA,oBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AACI;EACI,UAAA;AACR;AAEI;EACI,sBAAA;EACA,kBAAA;AAAR;AAGI;EACI,gBAAA;AADR","sourcesContent":[".content {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-end;\r\n    height: calc(100vh - 100px);\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.messages {\r\n    overflow-y: hidden;\r\n\r\n    &::-webkit-scrollbar {\r\n        width: 7px;\r\n    }\r\n\r\n    &::-webkit-scrollbar-thumb {\r\n        background-color: #555;\r\n        border-radius: 5px;\r\n    }\r\n\r\n    &:hover {\r\n        overflow-y: auto;\r\n    }\r\n}\r\n\r\n.messageInput {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_content__bq2tU`,
	"messages": `content_messages__ahXzk`
};
export default ___CSS_LOADER_EXPORT___;

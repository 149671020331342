import { useState, useRef, useEffect } from "react"

export const useContextMenu = () => {
    const [x, setX] = useState<number>(0)
    const [y, setY] = useState<number>(0)
    const [isVisibleContextMenu, setIsVisibleContextMenu] = useState<boolean>(false)
    const contextRef = useRef<HTMLDivElement>(null)

    const XScreenPosition = useRef<number>(0)
    const YScreenPosition = useRef<number>(0)
    const XClientPosition = useRef<number>(0)
    const YClientPosition = useRef<number>(0)

    const mouseEventType = useRef<string>('')

    const outsideClick = (e: any) => {
        const path = e.composedPath()
        if (path) {
            if (!path.includes(contextRef.current)) {
                setIsVisibleContextMenu(false)
            }
        }
    }
    const openContextMenu = (e: React.MouseEvent) => {
        e.preventDefault()

        mouseEventType.current = e.type

        XScreenPosition.current = e.screenX
        YScreenPosition.current = e.screenY
        XClientPosition.current = e.clientX
        YClientPosition.current = e.clientY

        const screenWidth = window.screen.width
        const screenContextMenu = e.screenX + 15 + Number(contextRef.current?.offsetWidth)

        if (e.type === 'contextmenu') {
            if (contextRef.current) {
                if (screenContextMenu > screenWidth) {
                    setX(e.clientX - 15 - Number(contextRef.current?.offsetWidth))
                    setY(e.clientY + 10)
                } else {
                    setX(e.clientX + 15)
                    setY(e.clientY + 10)
                }
            }
        }
        if (e.type === 'click') {
            setIsVisibleContextMenu(false)
        }
    }

    const closeContextMenu = () => {
        setIsVisibleContextMenu(false)
    }

    useEffect(() => {
        if (mouseEventType.current === 'contextmenu') {
            setIsVisibleContextMenu(true)
        }
    }, [x, y])

    useEffect(() => {
        if (isVisibleContextMenu) {
            const screenWidth = window.screen.width
            const screenContextMenu = XScreenPosition.current + 15 + Number(contextRef.current?.offsetWidth)

            if (screenContextMenu > screenWidth) {
                setX(XClientPosition.current - 15 - Number(contextRef.current?.offsetWidth))
                setY(YClientPosition.current + 10)
            } else {
                setX(XClientPosition.current + 15)
                setY(YClientPosition.current + 10)
            }
        }
    }, [isVisibleContextMenu])

    useEffect(() => {
        document.body.addEventListener('click', outsideClick)
    }, [])

    return { x, y, isVisibleContextMenu, contextRef, openContextMenu, closeContextMenu }
}
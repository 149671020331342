// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_btn__K25CW {
  display: inline-block;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  cursor: pointer;
}
.button_btn__K25CW:focus:after {
  content: "";
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px dashed #eee;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/button/button.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AACI;EACI,WAAA;EACA,cAAA;EACA,uBAAA;EACA,wBAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,uBAAA;EACA,kBAAA;AACR","sourcesContent":[".btn {\r\n    display: inline-block;\r\n    text-align: center;\r\n    user-select: none;\r\n    position: relative;\r\n    cursor: pointer;\r\n\r\n    &:focus:after {\r\n        content: '';\r\n        display: block;\r\n        width: calc(100% - 2px);\r\n        height: calc(100% - 2px);\r\n        outline: none;\r\n        position: absolute;\r\n        top: 0;\r\n        left: 0;\r\n        border: 1px dashed #eee;\r\n        border-radius: 5px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `button_btn__K25CW`
};
export default ___CSS_LOADER_EXPORT___;

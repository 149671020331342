import React, { FC, useState } from "react"
import Find from "../../components/find/find"
import Contacts from "../../components/contacts/contacts"
import Info from "../../components/info/info"
// import storeModel from "../../redux/store"
// import { contactsData } from "../../data/data"
import { useDispatch, useSelector } from "react-redux"
import { setActiveItemContact } from "../../redux/work/work.slice"
import { RootState } from "../../redux/store"
// import { setRecipients } from "../../redux/users/users.slice"
import { IUser } from "../../interfaces/users.interface"

import s from './main_layout.module.scss'
import Logo from "../../components/logo/logo"

interface MainLayoutProps {
    children: React.ReactNode
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
    const [activeContact, setActiveContact] = useState<IUser>()

    const contacts = useSelector((state: RootState) => state.user.users)
    // Id контакта с кем ведется беседа
    const activeItemContactId = useSelector((state: RootState) => state.work.activeItemContact)

    const dispatch = useDispatch()

    const handleClick = (id: number) => {
        dispatch(setActiveItemContact(id))
        setActiveContact(contacts.find(item => item.id === id))
    }

    return (
        <div className={s.main}>
            <div className={s.left}>
                <Find />
                <div className={s.contacts}>
                    <Contacts activeItemId={activeItemContactId} click={handleClick} />
                </div>
            </div>
            <div className={s.right}>
                <div className={s.info}>
                    {
                        (activeItemContactId > 0) ? (
                            <Info
                                name={activeContact?.name as string}
                                surname={activeContact?.surname as string}
                                avatar={activeContact?.avatar}
                                online={activeContact?.online as boolean}
                                // timePassed={contactsData[storeModel.activeItemConference - 1].timePassed}
                                // users={contactsData[storeModel.activeItemConference - 1].users}
                            />
                        ) : null
                    }

                </div>
                <div className={s.logo}><Logo /></div>
                <div>{children}</div>
            </div>
        </div>
    )
}

export default MainLayout
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_layout_main__SRfkw {
  height: 100vh;
  display: flex;
}

.main_layout_left__OY3V0 {
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.main_layout_right__8Se\\+r {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.main_layout_contacts__Yh6tf {
  height: calc(100vh - 22px);
}

.main_layout_info__DwMc0 {
  background-color: #fff;
  border-right: 1px solid #D0D0D0;
}

.main_layout_logo__-tt5C {
  position: absolute;
  top: 15px;
  right: 10px;
}

@media screen and (max-width: 1500px) {
  .main_layout_left__OY3V0 {
    width: 450px;
  }
}
@media screen and (max-width: 1300px) {
  .main_layout_left__OY3V0 {
    width: 400px;
  }
}
@media screen and (max-width: 1200px) {
  .main_layout_left__OY3V0 {
    width: 350px;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/main_layout/main_layout.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,aAAA;AAAJ;;AAIA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;AADJ;;AAQA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AALJ;;AAUA;EACI,0BAAA;AAPJ;;AAUA;EACI,sBAAA;EACA,+BAAA;AAPJ;;AAWA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;AARJ;;AAWA;EACI;IACI,YAAA;EARN;AACF;AAUA;EACI;IACI,YAAA;EARN;AACF;AAWA;EACI;IACI,YAAA;EATN;AACF","sourcesContent":[".main {\r\n    // width: 100vw;\r\n    height: 100vh;\r\n    display: flex;\r\n\r\n}\r\n\r\n.left {\r\n    width: 600px;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: #fff;\r\n    // border-right: 1px solid #D0D0D0;\r\n    // box-shadow: 1px 0px 15px 0px rgba(119, 119, 119, 0.46);\r\n    // position: relative;\r\n    // z-index: 100;\r\n}\r\n\r\n.right {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    position: relative;\r\n    // position: relative;\r\n    // z-index: 100;\r\n}\r\n\r\n.contacts {\r\n    height: calc(100vh - 22px);\r\n}\r\n\r\n.info {\r\n    background-color: #fff;\r\n    border-right: 1px solid #D0D0D0;\r\n    // box-shadow: 12px 7px 15px 0px rgba(119, 119, 119, 0.22);\r\n}\r\n\r\n.logo{\r\n    position: absolute;\r\n    top: 15px;\r\n    right: 10px;\r\n}\r\n\r\n@media screen and (max-width: 1500px) {\r\n    .left {\r\n        width: 450px;\r\n    }\r\n}\r\n@media screen and (max-width: 1300px) {\r\n    .left {\r\n        width: 400px;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n    .left {\r\n        width: 350px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `main_layout_main__SRfkw`,
	"left": `main_layout_left__OY3V0`,
	"right": `main_layout_right__8Se+r`,
	"contacts": `main_layout_contacts__Yh6tf`,
	"info": `main_layout_info__DwMc0`,
	"logo": `main_layout_logo__-tt5C`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mesage_item_item__LNaJl {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.mesage_item_avatar__afIT1 {
  margin-right: 10px;
  cursor: pointer;
}

.mesage_item_noAvatar__S9t7N {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: #F71C57;
  color: #fff;
}

.mesage_item_message__qxkYI {
  max-width: 55%;
  width: max-content;
  padding: 10px;
  border-radius: 10px;
}

.mesage_item_text__yRU5R {
  font-size: 16px;
  padding-bottom: 5px;
}

.mesage_item_time__sL6dH {
  font-size: 11px;
  color: #aaa;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.mesage_item_changed__slZ\\+f {
  margin-right: 3px;
}

.mesage_item_status__IL4g4 {
  margin-left: 5px;
}

.mesage_item_statusOk__bQJzl {
  position: absolute;
  right: -5px;
}`, "",{"version":3,"sources":["webpack://./src/components/messages/message_item/mesage_item.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AACA;EACI,kBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EAGA,yBAAA;EACA,WAAA;AAAJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAGA;EACI,eAAA;EACA,mBAAA;AAAJ;;AAKA;EAEI,eAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAHJ;;AAKA;EACI,iBAAA;AAFJ;;AAIA;EACI,gBAAA;AADJ;;AAGA;EACI,kBAAA;EACA,WAAA;AAAJ","sourcesContent":[".item{\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 20px;\r\n}\r\n.avatar{\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n}\r\n.noAvatar{\r\n    width: 50px;\r\n    height: 50px;\r\n    // border: 1px solid #000;\r\n    border-radius: 50%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 22px;\r\n    // font-weight: bold;\r\n    // background-color: #F5BB4E;\r\n    background-color: #F71C57;\r\n    color: #fff\r\n}\r\n.message{\r\n    max-width: 55%;\r\n    width: max-content;\r\n    padding: 10px;\r\n    border-radius: 10px;\r\n    // display: flex;\r\n    // align-items: center;\r\n}\r\n.text{\r\n    font-size: 16px;\r\n    padding-bottom: 5px;\r\n}\r\n.video{}\r\n.image{}\r\n.audio{}\r\n.time{\r\n    // text-align: right;\r\n    font-size: 11px;\r\n    color: #aaa;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n    position: relative;\r\n    cursor: pointer;\r\n}\r\n.changed{\r\n    margin-right: 3px;\r\n}\r\n.status{\r\n    margin-left: 5px;\r\n}\r\n.statusOk{\r\n    position: absolute;\r\n    right: -5px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `mesage_item_item__LNaJl`,
	"avatar": `mesage_item_avatar__afIT1`,
	"noAvatar": `mesage_item_noAvatar__S9t7N`,
	"message": `mesage_item_message__qxkYI`,
	"text": `mesage_item_text__yRU5R`,
	"time": `mesage_item_time__sL6dH`,
	"changed": `mesage_item_changed__slZ+f`,
	"status": `mesage_item_status__IL4g4`,
	"statusOk": `mesage_item_statusOk__bQJzl`
};
export default ___CSS_LOADER_EXPORT___;

import { FC, useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import { socket } from "../../socket/socket";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../messages/messages";
import MessageInput from "../message_input/message_input";
import allEndPoints from "../../api/api";
import { GroupType, MessageStatus, MessageType } from "../../enums/messages.enum";
import { IUser } from "../../interfaces/users.interface";
import { IMessage, ISocketMessage } from "../../interfaces/messages.interface";
import { constans } from "../../constans/constans";

import s from './content.module.scss'
import { decrementUserCountNotReadedMessages, incrementUserCountNotReadedMessages, resetUserCountNotReadedMessages, updateUserLastMessage } from "../../redux/users/users.slice";

interface ContentProps { }

const Content: FC<ContentProps> = ({ }) => {
    const dispatch = useDispatch()
    const [messages, setMessages] = useState<IMessage[]>([])
    const [newAddMessage, setNewAddMessage] = useState<IMessage>()
    const [newUpdateMessage, setNewUpdateMessage] = useState<IMessage>() // Редактирование/ответ сообщение
    const [newUpdateMessageStatus, setNewUpdateMessageStatus] = useState<IMessage>()
    const [newDeleteMessage, setNewDeleteMessage] = useState<IMessage>()
    const [users, setUsers] = useState<IUser[]>([])
    const [typeStatus, setTypeStatus] = useState<boolean>(false)

    const [idModifyMessage, setIdModifyMessage] = useState<number>(0) // Id сообщения, редактирование/ответ


    const [isAboveMessageInput, setIsAboveMessageInput] = useState<boolean>(false)
    const [aboveMessageInputContactName, setAboveMessageInputContactName] = useState<string>('')
    const [aboveMessageInputType, setAboveMessageInputType] = useState<'answer' | 'edit'>('edit')
    const [aboveMessageInputText, setAboveMessageInputText] = useState<string>('')
    const [aboveMessageInputMessageType, setAboveMessageInputMessageType] = useState<MessageType>(null)

    const messageContainer = useRef<HTMLDivElement>(null)
    const inputRef = useRef(null)

    const profile = useSelector((state: RootState) => state.user.profile)
    const usersFromStore = useSelector((state: RootState) => state.user.users)

    // Id контакта с кем ведется беседа
    const activeItemContactId = useSelector((state: RootState) => state.work.activeItemContact)

    const chahgeStatusFromNotReadedMessages = (messages: IMessage[]) => {
        messages?.forEach(m => {
            if (m.status === MessageStatus.NOT_READ) {
                if (m.user_id !== profile.id) { // Если чужое сообщение
                    // Отправляем сообщение на сервер - меняем статус сообщения на 'прочитано'
                    socket.emit(constans.CHANGE_MESSAGE_STATUS, {
                        user_id: m.user_id,
                        data: {
                            message_id: m.message_id,
                            status: MessageStatus.READ
                        }
                    } as ISocketMessage
                    )
                }
            }
        })
    }

    const handleChangeTypeStatus = (typeStatus: boolean) => {
        console.log('handleChangeTypeStatus')
        setTypeStatus(typeStatus)
    }

    const handleClickSendMessage = async (text: string) => {
        if (isAboveMessageInput) {
            const m = messages.find(item => item.id === idModifyMessage)
            if (aboveMessageInputType === 'edit') { // Редактирование сообщения
                let message: IMessage = { ...m, changed: true }
                if (aboveMessageInputMessageType === MessageType.TEXT) {
                    message = { ...message, data: { ...message.data, text } }
                }
                // Отправляем сообщение на сервер - редактируем сообщение
                socket.emit(constans.CHANGE_MESSAGE, { user_id: profile.id, data: message })
            }
            if (aboveMessageInputType === 'answer') { // Ответ на сообщение
                // Отправляем сообщение на сервер - отвечаем на сообщение
            }

            console.log('statuscontext', aboveMessageInputType);
            setIsAboveMessageInput(false)


        } else {
            // Формируем сообщение 
            const message = {
                message_id: uuidv4(),
                type: MessageType.TEXT,
                status: MessageStatus.NOT_READ,
                isLastMessage: true,
                changed: false,
                user_id: profile.id,
                users: [profile.id, activeItemContactId],
                data: { text },
                createdAt: new Date().toISOString(),
            }

            // TODO сохранение сообщения в localStorage для ситуации когда недоступен сервер
            // Использовать функции 'sevice/util.ts'

            // Отправляем сообщение на сервер - создаем сообщение
            socket.emit(constans.ADD_MESSAGE, { user_id: profile.id, data: message })
        }

    }

    const handleClickContextMenuItem = (slugContextMenu: string, message: IMessage) => {
        setIdModifyMessage(message.id)
        if (slugContextMenu === 'answer') {
            console.log('Ответ на сообщение с id=', message.id)
            // Ответ на сообщение
        }
        if (slugContextMenu === 'delete') {
            console.log('Удаление сообщения с id=', message.id)
            const lastMessage = messages[messages.length - 1]
            if (lastMessage.id === message.id) { // Последнее сообщение
                console.log('последнее сообщение', lastMessage)
                if (messages.length === 1) { //Всего одно сообщение
                    // Снимаем статус последнее сообщение (isLastMessage = false)
                    console.log('Всего одно сообщение')

                    socket.emit(constans.CHANGE_LAST_MESSAGE_STATUS, {
                        user_id: profile.id,
                        data:
                        {
                            message_id: lastMessage.message_id,
                            // users: penultimateMessage.group_ref.users,
                            isLastMessage: false
                        }
                    })
                } else {
                    // Предпоследнее сообщение
                    const penultimateMessage = messages[messages.length - 2]
                    // Присвоение статуса 'последнее сообщение' предпоследнему сообщению
                    // Отправляем сообщение на сервер - статуса 'последнее сообщение'
                    socket.emit(constans.CHANGE_LAST_MESSAGE_STATUS, {
                        user_id: profile.id,
                        data:
                        {
                            message_id: penultimateMessage.message_id,
                            // users: penultimateMessage.group_ref.users,
                            isLastMessage: true
                        }
                    })
                }
            }

            // Отправляем сообщение на сервер - удаление сообщения
            socket.emit(constans.DELETE_MESSAGE, { user_id: profile.id, data: message })
        }
        if (message.type === MessageType.TEXT) { // Текстовое сообщение
            if (slugContextMenu === 'copy') {
                // Копирование текста сообщения в буфер обмена
                navigator.clipboard.writeText(message.data.text)
            }
        }
        if (message.user_id === profile.id) { // Мое сообщение

            if (message.type === MessageType.TEXT) { // Текстовое сообщение
                if (slugContextMenu === 'edit') {
                    console.log('Редактирование сообщения с id=', message.id)
                    // Редактирование сообщения
                    setIsAboveMessageInput(true)
                    setAboveMessageInputType("edit")
                    setAboveMessageInputContactName('Вы')
                    setAboveMessageInputText(message.data.text)
                    setAboveMessageInputMessageType(MessageType.TEXT)
                }
            }
        }
    }

    const handleClickCloseAboveMessageInput = () => {
        setIsAboveMessageInput(false)
    }


    useEffect(() => {
        if (activeItemContactId > 0) {
            allEndPoints.auth.getUserByIdMin(activeItemContactId).then(d => setUsers([profile, d.data]))
            allEndPoints.messages.getMessages({ type: GroupType.PM, users: [activeItemContactId, profile.id] })
                .then(d => {
                    chahgeStatusFromNotReadedMessages(d.data)
                    setMessages(d.data)
                    // Обнуление счетчика непрочитанных сообщений
                    dispatch(resetUserCountNotReadedMessages({ id: activeItemContactId }))
                })
        }

        function onAddMessage(message: ISocketMessage) {
            if (message.data.group_ref.users.includes(profile.id)) {
                console.log('messagemessagemessage', message);

                dispatch(updateUserLastMessage(message.data.group_ref.users.map(userId => ({ id: userId, lastMessage: { data: message.data.type === MessageType.TEXT ? message.data.data.text : null, timeDate: message.data.updatedAt } }))))
                if (message.data.group_ref.users.includes(activeItemContactId)) { // Открыта переписка
                    setNewAddMessage(message.data)

                    if (message.user_id !== profile.id) { // Если чужое сообщение
                        // Отправляем сообщение на сервер - меняем статус сообщения на 'прочитано'
                        socket.emit(constans.CHANGE_MESSAGE_STATUS, {
                            user_id: message.user_id,
                            data: {
                                message_id: message.data.message_id,
                                status: MessageStatus.READ
                            }
                        } as ISocketMessage
                        )
                    }
                } else {
                    dispatch(incrementUserCountNotReadedMessages({ id: message.user_id }))
                }
            }
        }

        function onChangeMessage(message: ISocketMessage) {
            if (message.data.group_ref.users.includes(profile.id)) {
                setNewUpdateMessage(message.data)
            }
        }

        function onChangeMessageStatus(message: ISocketMessage) {
            if (message.user_id === profile.id) {
                setNewUpdateMessageStatus(message.data)
            }
        }

        function onChangeLastMessageStatus(message: ISocketMessage) {
            if (message.data.group_ref.users.includes(profile.id)) {
                dispatch(updateUserLastMessage(message.data.group_ref.users.map(userId => ({ id: userId, lastMessage: { data: message.data.type === MessageType.TEXT ? message.data.data.text : null, timeDate: message.data.updatedAt } }))))
            }
        }

        function onDeleteMessage(message: ISocketMessage) {
            if (message.data.group_ref.users.includes(profile.id)) {
                setNewDeleteMessage(message.data)
            }
        }

        socket.on(constans.ADD_MESSAGE, onAddMessage)
        socket.on(constans.CHANGE_MESSAGE, onChangeMessage)
        socket.on(constans.CHANGE_MESSAGE_STATUS, onChangeMessageStatus)
        socket.on(constans.CHANGE_LAST_MESSAGE_STATUS, onChangeLastMessageStatus)
        socket.on(constans.DELETE_MESSAGE, onDeleteMessage)
        return () => {
            socket.off(constans.ADD_MESSAGE, onAddMessage)
            socket.off(constans.CHANGE_MESSAGE, onChangeMessage)
            socket.off(constans.CHANGE_MESSAGE_STATUS, onChangeMessageStatus)
            socket.off(constans.CHANGE_LAST_MESSAGE_STATUS, onChangeLastMessageStatus)
            socket.off(constans.DELETE_MESSAGE, onDeleteMessage)
        }
    }, [activeItemContactId])

    useEffect(() => {
        if (newAddMessage) {
            setMessages([...messages, newAddMessage])
        }
    }, [newAddMessage])

    useEffect(() => {
        if (newUpdateMessage) {
            const mes = messages.map((item) => item.message_id === newUpdateMessage.message_id ? newUpdateMessage : item)
            setMessages(mes)
        }
    }, [newUpdateMessage])

    useEffect(() => {
        if (newUpdateMessageStatus) {
            const mes = messages.map((item) => item.message_id === newUpdateMessageStatus.message_id ? { ...item, status: newUpdateMessageStatus.status } : item)
            setMessages(mes)
        }
    }, [newUpdateMessageStatus])

    useEffect(() => {
        if (newDeleteMessage) {
            const mes = messages.filter((item) => item.id !== newDeleteMessage.id)
            setMessages(mes)

            // Уменьшаем счетчик непрочитанных сообщений
            dispatch(decrementUserCountNotReadedMessages({ id: newDeleteMessage.user_id }))
        }
    }, [newDeleteMessage])

    useEffect(() => {
        // Отправляем сообщение на сервер - меняем статус набора текста
        socket.emit(constans.CHANGE_TYPE_STATUS, { user_id: profile.id, data: { touser_id: activeItemContactId, typeStatus } } as ISocketMessage)
    }, [typeStatus])

    useEffect(() => {
        messageContainer.current.scrollTo({ top: messageContainer.current.scrollHeight })
        inputRef.current?.focus()
    }, [messages])

    return (
        <div className={s.content}>
            <div className={s.messages} ref={messageContainer}>
                {
                    activeItemContactId > 0 && <Messages messages={messages} users={users} userTyping={usersFromStore.find(user => user.id === activeItemContactId)} clickContextMenuItem={handleClickContextMenuItem} />
                }
            </div>
            <div className={s.messageInput}>
                {
                    activeItemContactId > 0 && (
                        <MessageInput
                            inputRef={inputRef}
                            isAboveMessageInput={isAboveMessageInput}
                            aboveMessageInputType={aboveMessageInputType}
                            aboveMessageInputText={aboveMessageInputText}
                            aboveMessageInputContactName={aboveMessageInputContactName}
                            aboveMessageInputMessageType={aboveMessageInputMessageType}
                            onChangeTypeStatus={handleChangeTypeStatus}
                            onClickSubmit={handleClickSendMessage}
                            onClickCloseAboveMessageInput={handleClickCloseAboveMessageInput}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default Content
import { FC } from "react"

import s from './count_not_readed_messages.module.scss'

interface CountNotReadedMessagesProps {
    value: number
}

const CountNotReadedMessages: FC<CountNotReadedMessagesProps> = ({ value }) => {
    return (
        <div className={s.item}>{value}</div>
    )
}

export default CountNotReadedMessages
import { FC } from 'react'
import { LuMessagesSquare } from "react-icons/lu";

import s from './nomessages.module.scss'

interface NoMessagesProps { }

const NoMessages: FC<NoMessagesProps> = ({ }) => {
    return (
        <div className={s.item}>
            <div className={s.ico}>
            <LuMessagesSquare size={80} />
            </div>
            <p className={s.text}>У вас нет сообщений</p>
        </div>
    )
}

export default NoMessages
import axios from "axios";
import env from 'react-dotenv'
import axiosInstance from "../axios/axios";
// import axiosInstance from "../axios/axios";

const authEndPoint = {
    // registartion: (data) => axios.post(`${process.env.API_URI}/api/auth/register`, data),
    login: (data: any) => axios.post(`${env.MAIN_SERVICE_API_HOST}/api/auth/login`, data),
    profile: () => axiosInstance.get(`${env.MAIN_SERVICE_API_HOST}/api/auth/profile`),
    getUsers: () => axiosInstance.get(`${env.AUTH_SERVICE_API_HOST}/api/user`),
    // getUsersExcludeByIdMin: (id: number) => axiosInstance.get(`${process.env.AUTH_SERVICE_API_HOST}/api/user/exclude/min/${id}`),
    getUsersExcludeByIdMin: (id: number) => axiosInstance.get(`${env.MAIN_SERVICE_API_HOST}/api/auth/users/exclude/min/${id}`),
    // getUserByIdMin: (id: number) => axiosInstance.get(`${process.env.AUTH_SERVICE_API_HOST}/api/user/min/${id}`),
    getUserByIdMin: (id: number) => axiosInstance.get(`${env.MAIN_SERVICE_API_HOST}/api/auth/user/min/${id}`),
    // getProfile: () => axiosInstance.get(`${process.env.API_URI}/api/auth/profile`),
    // getProfile: () => axios.get(`${process.env.API_URI}/api/user/profile`),

}

export default authEndPoint
import { PiShareFat } from "react-icons/pi";
import { VscEdit } from "react-icons/vsc";
import { MdContentCopy } from "react-icons/md";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IContextMenu } from "../interfaces/context-menu.interface";

export const messageContextMenu: IContextMenu = {
    content: [
        {
            text: 'Ответить',
            slug: 'answer',
            parent: '',
            ico: <PiShareFat size={20} />
        },
        {
            text: 'Изменить',
            slug: 'edit',
            parent: '',
            ico: <VscEdit size={18} /> 
            // ico: <GrClose size={13} color="#777" />
        },
        {
            text: 'Копировать',
            slug: 'copy',
            parent: '',
            ico: <MdContentCopy size={18} /> 
        },
        {
            text: 'Удалить',
            slug: 'delete',
            parent: '',
            ico: <MdOutlineDeleteOutline size={21} />
        }
    ]
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nomessages_item__krpa0 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 50px 100px;
  border-radius: 30px;
  color: #fff;
}

.nomessages_ico__b3Sqn {
  margin-bottom: 30px;
}

.nomessages_text__MKl-u {
  font-size: 26px;
}`, "",{"version":3,"sources":["webpack://./src/components/nomessages/nomessages.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":[".item{\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: rgba(0, 0, 0, .4);\r\n    padding: 50px 100px;\r\n    border-radius: 30px;\r\n    color: #fff;\r\n}\r\n.ico{\r\n    margin-bottom: 30px;\r\n}\r\n.text{\r\n    font-size: 26px;\r\n    // text-transform: uppercase;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `nomessages_item__krpa0`,
	"ico": `nomessages_ico__b3Sqn`,
	"text": `nomessages_text__MKl-u`
};
export default ___CSS_LOADER_EXPORT___;

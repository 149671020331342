import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IUser } from "../../interfaces/users.interface"

interface IUpdateUserStatus {
    id: number
    online: boolean
}
interface IUpdateUserCountNotReadedMessages {
    id: number
    count: number
}
interface IIncrementUserCountNotReadedMessages {
    id: number
}
interface IUpdateUserTypeStatus {
    id: number
    typeStatus: boolean
}

interface ILastMessage {
    data: string
    timeDate: string
}

interface IUpdateUserLastMessage {
    id: number
    lastMessage: ILastMessage
}

interface IUserState {
    profile: IUser,
    users: IUser[]
}

const initialState: IUserState = {
    profile: {} as IUser,
    users: []
}
export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setProfile: (state, action: PayloadAction<IUser>) => {
            state.profile = action.payload
        },
        setUsers: (state, action: PayloadAction<IUser[]>) => {
            state.users = action.payload
        },
        updateUserStatus: (state, action: PayloadAction<IUpdateUserStatus[]>) => {
            action.payload.forEach(payload => {
                const user = state.users.find(user => user.id === payload.id)
                if (user) {
                    user.online = payload.online
                }
            })
        },
        updateUserCountNotReadedMessages: (state, action: PayloadAction<IUpdateUserCountNotReadedMessages[]>) => {
            action.payload.forEach(payload => {
                const user = state.users.find(user => user.id === payload.id)
                if (user) {
                    user.notReadedMessages = payload.count
                }
            })
        },
        resetUserCountNotReadedMessages: (state, action: PayloadAction<IIncrementUserCountNotReadedMessages>) => {
            const user = state.users.find(user => user.id === action.payload.id)
            if (user) {
                user.notReadedMessages = 0
            }
        },
        incrementUserCountNotReadedMessages: (state, action: PayloadAction<IIncrementUserCountNotReadedMessages>) => {
            const user = state.users.find(user => user.id === action.payload.id)
            if (user) {
                if (user.notReadedMessages == null) {
                    console.log('user.notReadedMessages == null');
                    user.notReadedMessages = 1
                } else {
                    console.log('user.notReadedMessages++');
                    user.notReadedMessages = user.notReadedMessages + 1
                }
            }
        },
        decrementUserCountNotReadedMessages: (state, action: PayloadAction<IIncrementUserCountNotReadedMessages>) => {
            console.log('wewewewew', action);
            
            const user = state.users.find(user => user.id === action.payload.id)
            if (user) {
                // if (user.notReadedMessages == null) {
                //     console.log('user.notReadedMessages == null');
                //     user.notReadedMessages = 1
                // } else {
                //     console.log('user.notReadedMessages++');
                //     user.notReadedMessages = user.notReadedMessages + 1
                // }
                console.log('user', user);
                if (user.notReadedMessages) {
                    console.log('qwqwqwqwqwqwq');

                    user.notReadedMessages = user.notReadedMessages - 1
                }
            }
        },
        updateUserTypeStatus: (state, action: PayloadAction<IUpdateUserTypeStatus>) => {
            const user = state.users.find(user => user.id === action.payload.id)
            if (user) {
                user.typeStatus = action.payload.typeStatus
            }
        },
        updateUserLastMessage: (state, action: PayloadAction<IUpdateUserLastMessage[]>) => {
            console.log('action1111111111111111', action);
            action.payload.forEach(payload => {
                const user = state.users.find(user => user.id === payload.id)
                if (user) {
                    user.lastMessageData = payload.lastMessage.data
                    user.lastMessageTimeDate = payload.lastMessage.timeDate.slice(11, 16)
                }
            })
        },
    }
})

// экспортируем экшен и редьюсер
export const {
    setProfile,
    setUsers,
    updateUserStatus,
    updateUserCountNotReadedMessages,
    resetUserCountNotReadedMessages,
    incrementUserCountNotReadedMessages,
    decrementUserCountNotReadedMessages,
    updateUserTypeStatus,
    updateUserLastMessage
} = usersSlice.actions
export default usersSlice.reducer
import { FC, useState, useEffect, useRef } from "react";
import { socket } from "../../socket/socket";
import MainLayout from "../../layouts/main_layout/main_layout";
import Content from "../content/content";
import FormAuth from "../forms/form_auth/form_auth";
import { useDispatch, useSelector } from "react-redux";
import { setProfile, setUsers, updateUserCountNotReadedMessages, updateUserLastMessage, updateUserStatus, updateUserTypeStatus } from "../../redux/users/users.slice";
import { RootState } from "../../redux/store";
import { isObjEmpty } from "../../service/util";
import allEndPoints from "../../api/api";
import { constans } from "../../constans/constans";
import { IChangeTypeStatusUser, IChangeUserStatusMessage, IGetOnlineUsersMessage } from "../../interfaces/users.interface";
import { IMessage } from "../../interfaces/messages.interface";
import { MessageType } from "../../enums/messages.enum";

const Main: FC = () => {
    // const [contacts, setContacts] = useState<IUser[]>([])
    const [isAuth, setisAuth] = useState<boolean>(undefined)
    const [isErrorAuth, setIsErrorAuth] = useState<boolean>(false)

    const intervalRef = useRef(null)

    const profile = useSelector((state: RootState) => state.user.profile)
    const contacts = useSelector((state: RootState) => state.user.users)
    const dispatch = useDispatch()

    const getProfile = () => {
        allEndPoints.auth.profile()
            .then(d => {
                if (d?.data) {
                    setisAuth(true)
                    dispatch(setProfile(d.data))
                } else {
                    setisAuth(false)
                }
            })
            .catch(e => setisAuth(false))
    }

    const handleClickLogin = (login: string, password: string) => {
        allEndPoints.auth.login({ email: login, password })
            .then(d => {
                console.log(d.data);

                localStorage.setItem('accessToken', d.data.accessToken)
                getProfile()
            })
            .catch(e => setIsErrorAuth(true))
    }

    useEffect(() => {
        getProfile()
        const onConnect = () => {
            console.log('Socket connected')
        }
        const onDisconnect = () => {
            console.log('Socket disconnected')
            // // Установка статуса оффлайн
            // socket.emit(constans.CHANGE_USER_STATUS, { id: profile.id, data: { online: false}} as IChangeUserStatus)
        }

        socket.on(constans.CONNECT, onConnect)
        socket.on(constans.DISCONNECT, onDisconnect)

        return () => {
            // Disconnect
            //socket.emit(constans.CHANGE_USER_STATUS, {id: 1, data: {online: false}} as IChangeUserStatus)

            socket.off(constans.CONNECT, onConnect)
            socket.off(constans.DISCONNECT, onDisconnect)
        }
    }, [])

    useEffect(() => {
        const getUsers = async () => {
            // Запрос всех пользователей
            const { data: users } = await allEndPoints.auth.getUsersExcludeByIdMin(profile.id)
            const { data: nRMessages} = await allEndPoints.messages.getNotReadedMessages()
            const { data: lMessages} = await allEndPoints.messages.getLastMessages()
            console.log('users', users);
            console.log('nRMessages', nRMessages);
            console.log('lastMessages', lMessages);

            const notReadedMessages = nRMessages.filter(mes => mes.group_ref.users.includes(profile.id))

            var mapNotReadedMessages = notReadedMessages.reduce((acc, cur) => {
                acc[cur.user_id] = acc[cur.user_id] || [];
                acc[cur.user_id].push(cur);
                return acc;
            }, {});
            var notReadedMessagesToStore = [];
            for (var key in mapNotReadedMessages) {
                notReadedMessagesToStore.push(mapNotReadedMessages[key]);
            }

            const lastMessages: IMessage[] = lMessages.filter(mes => mes.group_ref.users.includes(profile.id))
            console.log('lastMessagesIncludes', lastMessages);

            var lastMessagessToStore = [];
            lastMessages.forEach(item => {
                item.group_ref.users.forEach(userId => {
                    if(userId !== profile.id){
                        lastMessagessToStore.push({
                            id: userId,
                            lastMessage: {
                                data: item.type === MessageType.TEXT ? item.data.text : null,
                                timeDate: item.updatedAt
                            } 
                        })
                    }
                })
                
            });

            console.log('lastMessagessToStorelastMessagessToStore', lastMessagessToStore);
            

            // var mapLastMessages = lastMessages.reduce((acc, cur) => {
            //     acc[cur.user_id] = acc[cur.user_id] || [];
            //     acc[cur.user_id].push(cur);
            //     return acc;
            // }, {});
            // var lastMessagessToStore = [];
            // for (var key in mapLastMessages) {
            //     lastMessagessToStore.push(mapLastMessages[key]);
            // }

            console.log(notReadedMessagesToStore);
            console.log(notReadedMessagesToStore.map(item => ({id: item[0].user_id, count: item.length})));

            dispatch(setUsers(users))
            dispatch(updateUserCountNotReadedMessages(notReadedMessagesToStore.map(item => ({id: item[0].user_id, count: item.length}))))
            dispatch(updateUserLastMessage(lastMessagessToStore))
            // dispatch(updateUserLastMessage(message.data.group_ref.users.map(userId => ({id: userId, lastMessage: {data: message.data.type === MessageType.TEXT ? message.data.data.text : null, timeDate: message.data.updatedAt} }))))

            // Запрос онлайн статуса всех users
            socket.emit(constans.GET_ONLINE_USERS, { id: profile.id, data: {} })          
        }
        if (!isObjEmpty(profile)) {
            getUsers()
        }
        const id = setInterval(() => {
            // Запрос всех онлайн users
            socket.emit(constans.USER_PRESENT_INTERVAL, { id: profile.id, data: {} })
        }, 3000)

        intervalRef.current = id

        return () => {
            clearInterval(intervalRef.current)
        }
    }, [profile])

    useEffect(() => {
        const onChangeUserStatus = (message: IChangeUserStatusMessage) => {
            console.log('onChangeUserStatus', message);
            dispatch(updateUserStatus([{ id: message.data.id, online: message.data.online }]))
        }
        const onGetOnlineUsers = (message: IGetOnlineUsersMessage) => {
            console.log('onGetOnlineUsers', message)
            if (profile.id && (profile.id === message.user_id)) { // Свое сообщение
                dispatch(updateUserStatus(message.data))
            }
        }
        const onChangeTypeStatusUsers = (message: IChangeTypeStatusUser) => {
            // console.log('onChangeTypeStatusUsers', message)
            if (profile.id && (profile.id === message.data.touser_id)) { // Свое сообщение
                dispatch(updateUserTypeStatus({id: message.user_id, typeStatus: message.data.typeStatus}))
            }
        }
        socket.on(constans.CHANGE_USER_STATUS, onChangeUserStatus)
        socket.on(constans.GET_ONLINE_USERS, onGetOnlineUsers)
        socket.on(constans.CHANGE_TYPE_STATUS, onChangeTypeStatusUsers) // Набирает текст
        return () => {
            socket.off(constans.CHANGE_USER_STATUS, onChangeUserStatus)
            socket.off(constans.GET_ONLINE_USERS, onGetOnlineUsers)
            socket.off(constans.GET_ONLINE_USERS, onChangeTypeStatusUsers)
        }
    }, [contacts])


    return (
        <div>
            {
                isAuth !== undefined ? (
                    <div>
                        {
                            isAuth ? (
                                <MainLayout>
                                    <Content />
                                </MainLayout>
                            ) : (
                                <FormAuth isErrorAuth={isErrorAuth} clickLogin={handleClickLogin} />
                            )
                        }
                    </div>
                ) : (
                    <div></div>
                )
            }
        </div >
    )
}

export default Main
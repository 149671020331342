import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IWorkState{
    activeItemContact: number
}

const initialState = {
    activeItemContact: -1
}
export const workSlice = createSlice({
    name: 'work',
    initialState,
    reducers: {
        setActiveItemContact: (state, action: PayloadAction<number>) => {
            state.activeItemContact = action.payload
        },
    }
})

// экспортируем экшен и редьюсер
export const { setActiveItemContact } = workSlice.actions
export default workSlice.reducer



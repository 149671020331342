import React, { FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { IoSend } from "react-icons/io5"

import s from './message_input.module.scss'
import AboveMessageInput from '../above_message_input/above_message_input'
import { MessageType } from '../../enums/messages.enum'

interface MassageInputProps {
    inputRef: any
    isAboveMessageInput: boolean
    aboveMessageInputContactName: string
    aboveMessageInputType: 'answer' | 'edit'
    aboveMessageInputText: string
    aboveMessageInputMessageType: MessageType
    onClickSubmit: (text: string) => void
    onChangeTypeStatus?: (typeStatus: boolean) => void
    onClickCloseAboveMessageInput: () => void
}

const MassageInput: FC<MassageInputProps> = ({
    inputRef,
    isAboveMessageInput,
    aboveMessageInputContactName,
    aboveMessageInputType,
    aboveMessageInputText,
    aboveMessageInputMessageType,
    onClickSubmit,
    onChangeTypeStatus,
    onClickCloseAboveMessageInput
}) => {
    const [text, setText] = useState<string>('')

    const timer1 = useRef(null)
    const timer2 = useRef(null)
    const typing = useRef(true)

    // Функция определяет набор текста
    function throttleTypeStatus(callee, timeout) {
        return function perform() {
            typing.current = true
            if (timer1.current) return
            timer1.current = setTimeout(() => {
                callee(true)
                clearTimeout(timer1.current)
                timer1.current = null
                typing.current = false
            }, timeout)
            if (timer2.current) return
            timer2.current = setTimeout(() => {
                if (!typing.current) {
                    callee(false)
                }
                clearTimeout(timer2.current)
                timer2.current = null
            }, timeout + 3000)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value)
        throttleTypeStatus(onChangeTypeStatus, 500)()
    }
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }
    const handleSubmit = () => {
        if (text) {
            onClickSubmit(text)
            setText('')
        }
        inputRef.current?.focus()
    }

    const handleClickCloseAboveMessageInput = () => {

        onClickCloseAboveMessageInput()
        inputRef.current?.focus()
    }

    useEffect(() => {
        setText(aboveMessageInputText)
        if (!isAboveMessageInput) {
            setText('')
        }
        inputRef.current?.focus()
    }, [isAboveMessageInput, aboveMessageInputText])

    console.log('aaa', isAboveMessageInput);

    return (
        <div>
            <div className={s.item}>
                <div className={s.inputWrap}>
                    <AboveMessageInput
                        style={{ height: isAboveMessageInput ? '38px' : '0px', marginBottom: isAboveMessageInput ? 10 : 0 }}
                        type={aboveMessageInputType}
                        contactName={aboveMessageInputContactName}
                        text={aboveMessageInputText}
                        messageType={aboveMessageInputMessageType}
                        clickClose={handleClickCloseAboveMessageInput}
                    />
                    <input className={s.input} type="text" value={text} ref={inputRef} onChange={handleChange} onKeyDown={handleKeyDown} autoFocus />
                </div>
                <div className={s.btn} onClick={handleSubmit}><IoSend className={s.btnIco} size={25} /></div>
            </div>
        </div>
    )
}

export default MassageInput
import { FC } from "react"

interface LogoProps { }

const Logo: FC<LogoProps> = ({ }) => {
    return (
        <svg width="101" height="72" viewBox="0 0 202 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M145.092 52.9571C145.092 76.7364 125.815 96.0133 102.036 96.0133C78.2564 96.0133 58.9795 76.7364 58.9795 52.9571C58.9795 29.1778 78.2564 9.90088 102.036 9.90088C125.815 9.90088 145.092 29.1778 145.092 52.9571Z"
                fill="url(#paint0_radial_593_22)" stroke="black" />
            <path
                d="M200.687 81.0294H174.981L172.304 93L167.484 63L159.986 101L153.739 63.714L146.955 89.5L144.099 81.0294H126.07L126 81.0363"
                stroke="black" strokeWidth="3" strokeLinejoin="round" />
            <path
                d="M0 81.0294H25.7053L28.3829 93.5L33.2027 63L40.7001 101L46.9479 63.714L53.7312 87.9913L56.5874 81.0294H74.6168L74.6866 81.0363"
                stroke="black" strokeWidth="3" strokeLinejoin="round" />
            <path
                d="M142.737 30.6436C142.737 30.6436 158.267 8.86531 151.484 2.97457C151.484 2.97457 150.413 1.01091 144.343 2.26053C138.274 3.51016 122.387 12.971 109.177 24.2171C95.9676 35.4631 86.5059 44.2101 73.8325 59.2048C61.1591 74.1996 60.0865 77.7698 55.9808 84.9101C51.8751 92.0505 48.8407 100.976 53.839 103.297C58.8372 105.617 68.2981 100.976 78.4732 94.5496"
                stroke="black" strokeWidth="2" />
            <path
                d="M144.521 33.678C158.267 14.042 157.553 4.04552 149.341 4.04553C141.13 4.04555 121.137 17.6122 107.57 29.3938C94.0031 41.1754 76.5083 60.5939 70.6185 68.3088C64.7287 76.0237 60.0866 82.768 58.4801 85.4456C56.8737 88.1233 53.1251 93.3 53.125 101.333C53.1249 109.366 65.9775 107.759 81.865 95.7992"
                stroke="black" strokeWidth="2" />
            <path
                d="M76.6877 13.8635C68.1193 7.25866 50.4469 -2.20232 48.3257 7.88216C47.8661 10.067 48.1264 12.0784 51.3395 19.5758C54.5526 27.0732 77.0448 61.8824 115.603 90.9794C115.603 90.9794 158.98 123.468 149.341 93.4785"
                stroke="black" strokeWidth="2" />
            <path
                d="M80.0797 11.9568C69.0122 4.28088 52.4107 -3.63031 51.5182 5.4737L51.5137 5.50218C51.2165 7.34348 50.8208 9.79638 54.0174 17.2552C57.2305 24.7526 79.9012 59.7404 118.459 88.8374C118.459 88.8374 163.8 122.397 150.591 88.8374"
                stroke="black" strokeWidth="2" />
            <path
                d="M75.7954 26.1807V82.054H85.0779V34.3921L116.852 34.2136V82.2325H126.313V26.1807H75.7954Z"
                stroke="black" fill="black" />
            <defs>
                <radialGradient id="paint0_radial_593_22" cx="0" cy="0" r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(90.6496 49.7039) rotate(76.3287) scale(48.1743 48.1742)">
                    <stop offset="0.165" stopColor="#EBEBEB" />
                    <stop offset="1" stopColor="#72699D" />
                </radialGradient>
            </defs>
        </svg>
    )
}

export default Logo
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts_contacts__zf42g {
  height: 100%;
  border-left: 1px solid #D0D0D0;
  border-right: 1px solid #D0D0D0;
  padding: 0px 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/contacts/contacts.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,8BAAA;EACA,+BAAA;EACA,iBAAA;AACJ","sourcesContent":[".contacts{\r\n    height: 100%;\r\n    border-left: 1px solid #D0D0D0;\r\n    border-right: 1px solid #D0D0D0;\r\n    padding: 0px 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts": `contacts_contacts__zf42g`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message_input_item__vcOxs {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0px 20px;
}

.message_input_inputWrap__dmoDt {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.message_input_input__3saF7 {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
}

.message_input_btn__NnQR3 {
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}
.message_input_btnIco__O9ahG {
  color: #555;
}
.message_input_btnIco__O9ahG:hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/message_input/message_input.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AACA;EACI,WAAA;EAEA,aAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,WAAA;EAGA,YAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;AADJ;;AAGA;EACI,iBAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AACI;EACI,WAAA;AACR;AAAQ;EACI,WAAA;AAEZ","sourcesContent":[".item{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: end;\r\n    padding: 0px 20px;\r\n}\r\n.inputWrap{\r\n    width: 100%;\r\n    // padding-right: 20px;\r\n    padding: 10px;\r\n    border-radius: 10px;\r\n    background-color: #fff;\r\n    // box-sizing: border-box;\r\n}\r\n.input{\r\n    width: 100%;\r\n    // padding: 10px;\r\n    // border-radius: 5px;\r\n    border: none;\r\n    outline: none;\r\n    font-size: 16px;\r\n    box-sizing: border-box;\r\n}\r\n.btn{\r\n    margin-left: 20px;\r\n    margin-bottom: 5px;\r\n    cursor: pointer;\r\n    &Ico{\r\n        color: #555;\r\n        &:hover{\r\n            color: #000;\r\n        }\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `message_input_item__vcOxs`,
	"inputWrap": `message_input_inputWrap__dmoDt`,
	"input": `message_input_input__3saF7`,
	"btn": `message_input_btn__NnQR3`,
	"btnIco": `message_input_btnIco__O9ahG`
};
export default ___CSS_LOADER_EXPORT___;

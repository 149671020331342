// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_ico_container__67aMg {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input_ico_ico__fLGNo {
  display: inline;
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/input_ico/input_ico.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;EACA,WAAA;AACJ;;AACA;EACI,eAAA;EACA,kBAAA;EACA,2BAAA;EACA,eAAA;AAEJ","sourcesContent":[".container{\r\n    position: relative; \r\n    display: inline-block;\r\n    width: 100%;\r\n}\r\n.ico{\r\n    display: inline;\r\n    position: absolute;\r\n    transform: translateY(-50%);\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `input_ico_container__67aMg`,
	"ico": `input_ico_ico__fLGNo`
};
export default ___CSS_LOADER_EXPORT___;

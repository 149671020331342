import { IMessageToLocalStorage } from "../interfaces/messages.interface"

export const isObjEmpty = (obj: any) => {
    if (Object.keys(obj).length === 0) {
        return true
    } else {
        return false
    }
}

// Удаление всех сообщений в localStorage
export const deleteAllMessagesFromLocalStorage = () => {
    localStorage.removeItem('messages')
}

// Добавление сообщения в localStorage
export const addMessageToLocalStorage = (message: IMessageToLocalStorage) => {
    let arr = []
    const messagesFromLocalStorage = JSON.parse(localStorage.getItem('messages'))
    if (messagesFromLocalStorage) {
        arr = [...messagesFromLocalStorage, message]
    } else {
        arr = [message]
    }

    localStorage.setItem('messages', JSON.stringify(arr))
}
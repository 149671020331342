import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import usersSlice from "./users/users.slice";
import workSlice from "./work/work.slice";

export const store = configureStore({
    reducer: {
        user: usersSlice,
        work: workSlice
        
    },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
import { FC } from "react"

import s from './label_input.module.scss'

interface LabelInputProps {
    labelText?: string
    inputText: string
    isInputEnable?: boolean
    labelFontSize?: number
    inputFontSize?: number
    inputWidth?: number | string
    inputPadding?: string
    labelInputWidth?: number | string
    flexGrow?: number
    gap?: number
    autocomplete?: string
    inputType?: 'text' | 'number' | 'password'
    tabIndex?: number | undefined
    autoFocus?: boolean
    style?: React.CSSProperties
    refE?: any
    error?: boolean    
    onBlur?: () => void
    onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const LabelInput: FC<LabelInputProps> = ({
    labelText,
    inputText,
    isInputEnable = true,
    labelFontSize = 16,
    inputFontSize = 14,
    inputWidth = 150,
    inputPadding = '5px 10px',
    labelInputWidth,
    flexGrow = 0,
    gap,
    autocomplete,
    inputType = 'text',
    refE,
    tabIndex,
    autoFocus,
    style,
    onBlur,
    onChangeInput,
}) => { 

    return (
        <div className={s.item} style={{
            ...style,
            width: labelInputWidth ? labelInputWidth : 'initial',
            fontSize: labelFontSize,
            justifyContent: labelInputWidth ? 'space-between' : 'flex-start'

            }}>
            <div className={s.label} style={{ marginRight: gap ? gap : 0}}>{labelText}</div>
            {
                isInputEnable ?
                    <input className={s.input} style={{
                        backgroundColor: '#FFFFFF',
                        width: inputWidth,
                        flexGrow: flexGrow,
                        padding: inputPadding,
                        fontSize: inputFontSize,                        
                    }} type={inputType} ref={refE} 
                        onBlur={onBlur} value={inputText}
                        tabIndex={tabIndex} onChange={onChangeInput}
                        autoFocus={autoFocus} autoComplete={autocomplete}/>
                    :
                    <input className={s.input} style={{
                        backgroundColor: '#F0F0F0',
                        width: inputWidth,
                        flexGrow: flexGrow,
                        padding: inputPadding,                        
                        fontSize: inputFontSize,
                    }} type={inputType} ref={refE} 
                    autoComplete={autocomplete}
                    tabIndex={-1} onBlur={onBlur} value={inputText} disabled />
            }
        </div>
    );
}

export default LabelInput
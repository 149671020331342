// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.above_message_input_item__A9roa {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
  transition: 0.3s;
}

.above_message_input_content__WywIc {
  display: flex;
  align-items: center;
  background-color: #fff;
}

.above_message_input_ico__4epJH {
  margin-right: 10px;
}

.above_message_input_info__hfpss {
  border-left: 2px solid #636dee;
  padding-left: 5px;
}
.above_message_input_info__hfpss.above_message_input_answer__KaJ8Q {
  border-left: 2px solid #636dee;
}
.above_message_input_info__hfpss.above_message_input_edit__2MSua {
  border-left: 2px solid #F71C57;
}

.above_message_input_top__IXsqV {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.above_message_input_top__IXsqV.above_message_input_answer__KaJ8Q {
  color: #636dee;
}
.above_message_input_top__IXsqV.above_message_input_edit__2MSua {
  color: #F71C57;
}

.above_message_input_bottom__3sDRn {
  font-size: 14px;
  color: #aaa;
}

.above_message_input_close__u0rq- {
  padding: 3px;
  cursor: pointer;
  border-radius: 50%;
}
.above_message_input_close__u0rq-:hover {
  background-color: #eee;
}`, "",{"version":3,"sources":["webpack://./src/components/above_message_input/above_message_input.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,qBAAA;EAEA,gBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,8BAAA;EACA,iBAAA;AAAJ;AAEI;EACI,8BAAA;AAAR;AAGI;EACI,8BAAA;AADR;;AAKA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;AAFJ;AAII;EACI,cAAA;AAFR;AAKI;EACI,cAAA;AAHR;;AAOA;EACI,eAAA;EACA,WAAA;AAJJ;;AAOA;EACI,YAAA;EACA,eAAA;EACA,kBAAA;AAJJ;AAKI;EACI,sBAAA;AAHR","sourcesContent":[".item {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: baseline;\r\n    // margin-bottom: 10px;\r\n    overflow: hidden;\r\n    transition: 0.3s;\r\n}\r\n\r\n.content {\r\n    display: flex;\r\n    align-items: center;\r\n    background-color: #fff;\r\n}\r\n\r\n.ico {\r\n    margin-right: 10px;\r\n}\r\n\r\n.info {\r\n    border-left: 2px solid #636dee;\r\n    padding-left: 5px;\r\n\r\n    &.answer {\r\n        border-left: 2px solid #636dee;\r\n    }\r\n\r\n    &.edit {\r\n        border-left: 2px solid #F71C57;\r\n    }\r\n}\r\n\r\n.top {\r\n    font-size: 15px;\r\n    font-weight: bold;\r\n    margin-bottom: 5px;\r\n\r\n    &.answer {\r\n        color: #636dee;\r\n    }\r\n\r\n    &.edit {\r\n        color: #F71C57;\r\n    }\r\n}\r\n\r\n.bottom {\r\n    font-size: 14px;\r\n    color: #aaa;\r\n}\r\n\r\n.close {\r\n    padding: 3px;\r\n    cursor: pointer;\r\n    border-radius: 50%;\r\n    &:hover{\r\n        background-color: #eee;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `above_message_input_item__A9roa`,
	"content": `above_message_input_content__WywIc`,
	"ico": `above_message_input_ico__4epJH`,
	"info": `above_message_input_info__hfpss`,
	"answer": `above_message_input_answer__KaJ8Q`,
	"edit": `above_message_input_edit__2MSua`,
	"top": `above_message_input_top__IXsqV`,
	"bottom": `above_message_input_bottom__3sDRn`,
	"close": `above_message_input_close__u0rq-`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context_menu_menu__x79w0 {
  font-size: 14px;
  position: fixed;
  z-index: -999;
  opacity: 0;
  padding: 7px 0px;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  box-shadow: 5px 10px 27px -1px rgba(207, 207, 207, 0.5);
  cursor: pointer;
}
.context_menu_menu__x79w0.context_menu_active__UtGLp {
  z-index: 999;
  opacity: 1;
}

.context_menu_item__YOdv6 {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  transition: 0.3s;
}
.context_menu_item__YOdv6:hover {
  background-color: #eee;
}

.context_menu_ico__kAHTZ {
  position: relative;
  top: 1px;
  margin-right: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/contex_menu/context_menu.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;EACA,aAAA;EACA,UAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EAGA,uDAAA;EACA,eAAA;AACJ;AAAI;EACI,YAAA;EACA,UAAA;AAER;;AAEA;EACI,aAAA;EAEA,mBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,sBAAA;AAAR;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,iBAAA;AADJ","sourcesContent":[".menu {\r\n    font-size: 14px;\r\n    position: fixed;\r\n    z-index: -999;\r\n    opacity: 0;\r\n    padding: 7px 0px;\r\n    border-radius: 5px;\r\n    border: 1px solid #ebebeb;\r\n    -webkit-box-shadow: 5px 10px 27px -1px rgba(207, 207, 207, 0.5);\r\n    -moz-box-shadow: 5px 10px 27px -1px rgba(207, 207, 207, 0.5);\r\n    box-shadow: 5px 10px 27px -1px rgba(207, 207, 207, 0.5);\r\n    cursor: pointer;\r\n    &.active{\r\n        z-index: 999;\r\n        opacity: 1;\r\n    }\r\n}\r\n\r\n.item {\r\n    display: flex;\r\n    // justify-content: space-between;\r\n    align-items: center;\r\n    padding: 5px 15px;\r\n    transition: .3s;\r\n\r\n    &:hover {\r\n        background-color: #eee;\r\n    }\r\n}\r\n\r\n.ico {\r\n    position: relative;\r\n    top: 1px;\r\n    margin-right: 7px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `context_menu_menu__x79w0`,
	"active": `context_menu_active__UtGLp`,
	"item": `context_menu_item__YOdv6`,
	"ico": `context_menu_ico__kAHTZ`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_content_content__2TUDr {
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: wrap;
}
.button_content_content__2TUDr.button_content_noWrap__JH1Wt {
  text-wrap: nowrap;
}

.button_content_text__tNt6L {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/button/button_content/button_content.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACJ;AACI;EACI,iBAAA;AACR;;AAGA;EACI,kBAAA;AAAJ","sourcesContent":[".content {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    text-wrap: wrap;\r\n\r\n    &.noWrap {\r\n        text-wrap: nowrap;\r\n    }\r\n}\r\n\r\n.text {\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `button_content_content__2TUDr`,
	"noWrap": `button_content_noWrap__JH1Wt`,
	"text": `button_content_text__tNt6L`
};
export default ___CSS_LOADER_EXPORT___;

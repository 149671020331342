import { FC } from "react"

import s from './contact_item.module.scss'
import CountNotReadedMessages from "../../count_not_readed_messages/count_not_readed_messages"

interface ContactItemProps {
    id: number
    name: string
    surname: string
    avatar?: string
    lastMessage?: string
    countNotReadedMessages?: number
    time?: string
    status?: boolean
    typeStatus?: boolean
    active?: boolean
    click: (id: number) => void
}

const ContactItem: FC<ContactItemProps> = ({ id, name, surname, avatar, lastMessage, countNotReadedMessages, time, status = false, typeStatus = false, active = false, click }) => {
    return (
        <div className={active ? `${s.item} ${s.active}` : s.item} onClick={() => click(id)}>
            <div className={s.ico}>
                {
                    avatar ? <img src={'img/contactIcoActive.png'} alt="contactIco" /> : <div className={s.noAvatar}>{`${surname[0]}${name[0]}`}</div>
                }
                {
                    status && <div className={s.status}></div>
                }
            </div>
            <div className={s.block}>
                <div className={s.text}>
                    <div
                        className={s.contactName}
                    // style={{ color: active ? '#FFF' : '#000' }}
                    >
                        {`${surname} ${name}`}
                    </div>
                    <div
                        className={s.lastMessage}
                    // style={{ color: active ? '#d1d1d1' : '#c7c7c7' }}
                    >
                        {
                            typeStatus ? 'Печает' : lastMessage
                        }
                    </div>
                </div>
                <div
                    className={s.time}
                // style={{ color: active ? '#FFF' : '#000' }}
                >
                    {time}
                </div>
            </div>
            {
                countNotReadedMessages > 0 && <CountNotReadedMessages value={countNotReadedMessages} />
            }
        </div>
    )
}

export default ContactItem
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_input__23xip {
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 1px 1px 5.9000000954px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  font-size: 16;
  padding: 10px;
}
.input_input__23xip.input_error__2gw9F {
  border: 1px solid rgba(255, 0, 0, 0.9);
  box-shadow: 1px solid rgba(255, 0, 0, 0.2);
}
.input_input__23xip.input_disable__7AJ8d {
  background-color: #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/input.module.scss","webpack://./src/styles/common_styles.scss","webpack://./src/theme.scss"],"names":[],"mappings":"AAEA;ECKI,iBAAA;EACA,oCAAA;EACA,kBAAA;EACA,sDAAA;EACA,sBAAA;EDPA,aAAA;EACA,aAAA;AAGJ;ACMI;EACI,sCCDW;EDEX,0CCDkB;AFH1B;ACOI;EACI,yBAAA;ADLR","sourcesContent":["@use \"./../../styles/common_styles.scss\";\r\n\r\n.input {\r\n    @include common_styles.input_style;\r\n    font-size: 16;\r\n    padding: 10px;\r\n}\r\n\r\n","\r\n@use \"../theme.scss\";\r\n\r\n\r\n//$error_border2: 10px solid rgba(224, 0, 0, 0.9);\r\n\r\n@mixin input_style {\r\n    font-weight: bold;\r\n    border: 1px solid rgba(0, 0, 0, 0.2);\r\n    border-radius: 5px;\r\n    box-shadow: 1px 1px 5.900000095367432px rgba(0, 0, 0, 0.15);\r\n    box-sizing: border-box;\r\n    \r\n    \r\n    &.error {\r\n        border: theme.$error_border;//, 1px solid rgba(255, 0, 0, 0.9));\r\n        box-shadow: theme.$error_border_shadow;// 1px solid rgba(255, 0, 0, 0.2);\r\n    }\r\n\r\n    &.disable {\r\n        background-color: #e0e0e0;\r\n    }\r\n}\r\n\r\n@mixin lable_style {\r\n    font-weight: bold;\r\n}","\r\n$themes: 'rmts';\r\n\r\n\r\n// Стили элементов в начальном состоянии\r\n\r\n// Атрибуты елемента с ошибкой ввода\r\n$error_border: 5px solid rgba(224, 0, 0, 0.9);\r\n$error_border_shadow: 5px solid rgba(255, 251, 0, 0.932);\r\n// Атрибуты задизабленного элемента\r\n$disable_bg_color: #a0a0a0;\r\n\r\n@if $themes == \"rmts\" {\r\n    // Атрибуты елемента с ошибкой ввода\r\n    $error_border: 1px solid rgba(255, 0, 0, 0.9);\r\n    $error_border_shadow: 1px solid rgba(255, 0, 0, 0.2);\r\n\r\n    // Атрибуты задизабленного элемента\r\n    $disable_bg_color: #e0e0e0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `input_input__23xip`,
	"error": `input_error__2gw9F`,
	"disable": `input_disable__7AJ8d`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages_messages__8pdJ\\+ {
  padding: 20px 20px 20px;
}

.messages_list__QtTBw {
  position: relative;
}

.messages_typing__iCjNn {
  font-style: italic;
  font-size: 14px;
  color: #000;
  position: absolute;
  bottom: -30px;
  left: 0;
}
.messages_typing__iCjNn span {
  padding: 5px;
  border-radius: 5px;
  background-color: #eee;
}`, "",{"version":3,"sources":["webpack://./src/components/messages/messages.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,OAAA;AACJ;AACI;EACI,YAAA;EACA,kBAAA;EACA,sBAAA;AACR","sourcesContent":[".messages {\r\n    padding: 20px 20px 20px;\r\n}\r\n\r\n.list {\r\n    position: relative;\r\n}\r\n\r\n.typing {\r\n    font-style: italic;\r\n    font-size: 14px;\r\n    color: #000;\r\n    position: absolute;\r\n    bottom: -30px;\r\n    left: 0;\r\n\r\n    span {\r\n        padding: 5px;\r\n        border-radius: 5px;\r\n        background-color: #eee;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": `messages_messages__8pdJ+`,
	"list": `messages_list__QtTBw`,
	"typing": `messages_typing__iCjNn`
};
export default ___CSS_LOADER_EXPORT___;

// import axios from "axios";
import { GroupType, MessageType } from "../../enums/messages.enum";
import env from 'react-dotenv'
import axiosInstance from "../axios/axios";
// import axiosInstance from "../axios/axios";

type TMessageData = {
    text?: string
    filename?: string
}
type TCreateMessage = {
    type: MessageType
    user_id: number
    group_id?: number
    users?: number[]
    data: TMessageData
}

type TGetMessages = {
    type: GroupType
    users: number[]
}


const messagesEndPoint = {
    createMessage: (data: TCreateMessage) => axiosInstance.post(`${env.MAIN_SERVICE_API_HOST}/api/message/create`, data),
    // getMessages: (data: TGetMessages) => axiosInstance.post(`http://localhost:3500/api/message`, data),
    getMessages: (data: TGetMessages) => axiosInstance.post(`${env.MAIN_SERVICE_API_HOST}/api/messages`, data),
    getNotReadedMessages: () => axiosInstance.post(`${env.MAIN_SERVICE_API_HOST}/api/messages/filter`, { "itemName": "status", "payload": "not_read" }),
    getLastMessages: () => axiosInstance.post(`${env.MAIN_SERVICE_API_HOST}/api/messages/filter`, { "itemName": "isLastMessage", "payload": "true" }),
    // registartion: (data) => axios.post(`${process.env.API_URI}/api/auth/register`, data),
    // login: (data: any) => axios.post(`${process.env.AUTH_SERVICE_API_HOST}/api/auth/login`, data),
    // getProfile: () => axiosInstance.get(`${process.env.API_URI}/api/auth/profile`),
    // getProfile: () => axios.get(`${process.env.API_URI}/api/user/profile`),

}

export default messagesEndPoint
import { FC } from "react"

import s from './info.module.scss'

interface InfoProps {
    name: string
    surname: string
    avatar?: string 
    online: boolean
    timePassed?: string
    // users: any[]
}

const Info: FC<InfoProps> = ({ name, surname, avatar, online, timePassed }) => {
    return (
        <div className={s.info}>
            <div className={s.ico}>
                {
                   avatar ? <img src={'img/contactIcoActive.png'} alt="contactIco" /> : <div className={s.noAvatar}>{`${surname[0]}${name[0]}`}</div> 
                }
            </div>
            <div className={s.block}>
                <div className={s.top}>
                    <div className={s.name}>{`${surname} ${name}`}</div>
                    <div className={s.status}>{online ? 'Online' : 'Offline'}</div>
                </div>
                {/* <div className={s.bottom}>
                    <span className={s.count}>{`${users.length} человек(а) `}</span>
                    <span className={s.users}>{`(${users.map(item => item.name).join(', ')})`}</span>
                </div> */}
            </div>
        </div>
    )
}

export default Info
import React, { FC, useState } from "react"

import s from './form_auth.module.scss'
import LabelInput from "../../label_input/label_input"
import Logo from "../../logo/logo"
import Button from "../../buttons/button/button"

interface FormAuthProps {
    isErrorAuth: boolean
    clickLogin: (login: string, password: string) => void
}

const FormAuth: FC<FormAuthProps> = ({ isErrorAuth = false, clickLogin }) => {
    const [login, setLogin] = useState<string>()
    const [password, setPassword] = useState<string>()

    const handleChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogin(e.target.value)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handleClickLogin = () => {
        clickLogin(login, password)
    }

    return (
        <div className={s.background}>
            <div className={s.form}>
                <div className={s.logo}>
                    <Logo />
                    <span>Пульсар-Телеком</span>
                </div>
                <div className={s.item}>
                    <LabelInput labelText="Логин" inputText={login} inputFontSize={12} gap={50} inputPadding="7px 10px" onChangeInput={handleChangeLogin} />
                </div>
                <div className={s.item}>
                    <LabelInput labelText="Пароль" inputText={password} inputFontSize={12} inputType="password" gap={38} inputPadding="7px 10px" onChangeInput={handleChangePassword} />
                </div>
                <div className={isErrorAuth ? `${s.error} ${s.active}` : s.error}>Неверный логин или пароль</div>
                <div className={s.btn}>
                    <Button text="Логин" backgroundColor="#645E83" backgroundColorHover="#fff" transitionHover=".5s" borderRadius={5} onClick={handleClickLogin} />
                </div>
            </div>
        </div>
    )
}

export default FormAuth
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_auth_background__cfLPi {
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.form_auth_logo__AwbIV {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form_auth_logo__AwbIV span {
  margin-left: 10px;
  position: relative;
  top: -10px;
  font-size: 22px;
  font-weight: bold;
  -webkit-user-select: none;
          user-select: none;
}

.form_auth_item__OjTiR {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.form_auth_error__omP9k {
  color: red;
  font-size: 12px;
  display: flex;
  justify-content: center;
  opacity: 0;
}
.form_auth_error__omP9k.form_auth_active__cqw8u {
  opacity: 1;
}

.form_auth_btn__OSqez {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/form_auth/form_auth.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;UAAA,iBAAA;AACR;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,UAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;AAAJ;AACI;EACI,UAAA;AACR;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".background {\r\n    height: 100vh;\r\n    background-color: #fff;\r\n    display: flex;\r\n    justify-content: center;\r\n    padding-top: 30px;\r\n}\r\n\r\n.logo {\r\n    display: flex;\r\n    align-items: center;\r\n    margin-bottom: 10px;\r\n\r\n    span {\r\n        margin-left: 10px;\r\n        position: relative;\r\n        top: -10px;\r\n        font-size: 22px;\r\n        font-weight: bold;\r\n        user-select: none;\r\n    }\r\n}\r\n\r\n.item {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.error{\r\n    color: red;\r\n    font-size: 12px;\r\n    display: flex;\r\n    justify-content: center;\r\n    opacity: 0;\r\n    &.active{\r\n        opacity: 1;\r\n    }\r\n}\r\n\r\n.btn {\r\n    display: flex;\r\n    justify-content: center;\r\n    padding-top: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `form_auth_background__cfLPi`,
	"logo": `form_auth_logo__AwbIV`,
	"item": `form_auth_item__OjTiR`,
	"error": `form_auth_error__omP9k`,
	"active": `form_auth_active__cqw8u`,
	"btn": `form_auth_btn__OSqez`
};
export default ___CSS_LOADER_EXPORT___;

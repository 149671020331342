// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info_info__up87P {
  display: flex;
  align-items: center;
  padding: 10px 30px;
}

.info_ico__E7PHQ {
  margin-right: 15px;
}
.info_ico__E7PHQ img {
  width: 60px;
  height: 60px;
}

.info_noAvatar__XzqyB {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: #F71C57;
  color: #fff;
}

.info_top__pkI7U {
  display: flex;
  align-items: center;
}

.info_name__X-5Da {
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
}

.info_status__qRnGZ {
  font-size: 16px;
  font-style: italic;
}

.info_count__NlsbL {
  font-size: 16px;
  font-weight: bold;
}

.info_users__neLvf {
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  .info_name__X-5Da {
    font-size: 16px;
  }
  .info_time__1xXJy {
    font-size: 14px;
  }
  .info_count__NlsbL {
    font-size: 14px;
  }
  .info_users__neLvf {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/info/info.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;AADI;EACI,WAAA;EACA,YAAA;AAGR;;AAAA;EACI,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EAGA,yBAAA;EACA,WAAA;AAAJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AACA;EACI,eAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,eAAA;EACA,iBAAA;AAGJ;;AADA;EACI,eAAA;AAIJ;;AADA;EACI;IACI,eAAA;EAIN;EADE;IACI,eAAA;EAGN;EADE;IACI,eAAA;EAGN;EADE;IACI,eAAA;EAGN;AACF","sourcesContent":[".info{\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 10px 30px;\r\n}\r\n.ico{\r\n    margin-right: 15px;\r\n    img{\r\n        width: 60px;\r\n        height: 60px;\r\n    }\r\n}\r\n.noAvatar{\r\n    width: 60px;\r\n    height: 60px;\r\n    // border: 1px solid #000;\r\n    border-radius: 50%;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 22px;\r\n    // font-weight: bold;\r\n    // background-color: #F5BB4E;\r\n    background-color: #F71C57;\r\n    color: #fff\r\n}\r\n.top{\r\n    display: flex;\r\n    align-items: center;\r\n    // margin-bottom: 15px;\r\n}\r\n.name{\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    margin-right: 15px;\r\n}\r\n.status{\r\n    font-size: 16px;\r\n    font-style: italic;\r\n}\r\n.count{\r\n    font-size: 16px;\r\n    font-weight: bold;\r\n}\r\n.users{\r\n    font-size: 16px;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n    .name{\r\n        font-size: 16px;\r\n        // margin-right: 15px;\r\n    }\r\n    .time{\r\n        font-size: 14px;\r\n    }\r\n    .count{\r\n        font-size: 14px;\r\n    }\r\n    .users{\r\n        font-size: 14px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `info_info__up87P`,
	"ico": `info_ico__E7PHQ`,
	"noAvatar": `info_noAvatar__XzqyB`,
	"top": `info_top__pkI7U`,
	"name": `info_name__X-5Da`,
	"status": `info_status__qRnGZ`,
	"count": `info_count__NlsbL`,
	"users": `info_users__neLvf`,
	"time": `info_time__1xXJy`
};
export default ___CSS_LOADER_EXPORT___;

export const constans = {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    ADD_MESSAGE: 'add_message',
    CHANGE_MESSAGE: 'change_message',
    CHANGE_MESSAGE_STATUS: 'change_message_status',
    CHANGE_LAST_MESSAGE_STATUS: 'change_last_message_status',
    USER_PRESENT_INTERVAL: 'user_present_interval',
    GET_ONLINE_USERS: 'get_online_users',
    CHANGE_USER_STATUS: 'change_user_status',
    CHANGE_TYPE_STATUS: 'change_type_status',
    DELETE_MESSAGE: 'delete_message',
}
import { FC } from "react"
import { RootState } from "../../redux/store"
import ContactItem from "./contact_item/contact_item"
import { useSelector } from "react-redux"

import s from './contacts.module.scss'

interface ContactsProps {
    activeItemId: number
    click: (id: number) => void
}

const Contacts: FC<ContactsProps> = ({ activeItemId, click }) => {
    const contacts = useSelector((state: RootState) => state.user.users)
    return (
        <div className={s.contacts}>
            {
                contacts.map((item) => (
                    <ContactItem
                        id={item.id}
                        name={item.name as string}
                        surname={item.surname as string}
                        avatar={item.avatar as string}
                        status={item.online}
                        typeStatus={item.typeStatus}
                        lastMessage={item.lastMessageData ? item.lastMessageData : `\u3164`}
                        countNotReadedMessages={item.notReadedMessages}
                        time={item.lastMessageTimeDate}
                        active={item.id === activeItemId ? true : false}
                        click={click}
                        key={item.id}
                    />
                ))
            }
        </div>
    )
}

export default Contacts
import { FC } from "react"
import { MdCheck } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { GroupType, MessageStatus, MessageType } from "../../../enums/messages.enum"
import { IUser } from "../../../interfaces/users.interface"

import s from './mesage_item.module.scss'

interface MessageItemProps {
    text: string
    fileName: string
    type: MessageType
    status: MessageStatus
    isStatus: boolean
    groupType: GroupType
    changed: boolean
    // user_id: number
    createdAt: any
    updatedAt: any
    backgroundColor: string
    user: IUser
    clickContextMenu: (e: React.MouseEvent) => void
}

const MessageItem: FC<MessageItemProps> = ({
    text,
    fileName,
    type,
    status,
    isStatus,
    groupType,
    changed,
    // user_id,
    createdAt,
    updatedAt,
    backgroundColor,
    user,
    clickContextMenu
}) => {
    return (
        <li className={s.item}>
            <div className={s.avatar}>
                {
                    user?.avatar ? <img src={user.avatar} alt="contactIco" /> : <div className={s.noAvatar}>{`${user?.surname[0]}${user?.name[0]}`}</div>
                }
            </div>
            <div className={s.message} style={{ backgroundColor }}>
                <div onContextMenu={clickContextMenu}>
                    {
                        type === MessageType.TEXT ? <p className={s.text}>{text}</p> : (
                            <div>
                                {
                                    type === MessageType.VIDEO && <div className={s.video}></div>
                                }
                                {
                                    type === MessageType.IMAGE && <div className={s.image}></div>
                                }
                                {
                                    type === MessageType.AUDIO && <div className={s.audio}></div>
                                }
                                <p className={s.text}>{text}</p>
                            </div>
                        )
                    }
                    {
                        (isStatus && (status === MessageStatus.NOT_READ)) && <div className={s.time}>{changed && <MdEdit className={s.changed} size={14} />}{changed ? updatedAt.slice(11, 16) : createdAt.slice(11, 16)}<MdCheck className={s.status} size={15} color="#aaa" /></div>
                    }
                    {
                        (isStatus && (status === MessageStatus.READ)) && <div className={s.time}>{changed && <MdEdit className={s.changed} size={14} />}{changed ? updatedAt.slice(11, 16) : createdAt.slice(11, 16)}<MdCheck className={s.status} size={15} color="#636DEE" /><MdCheck className={s.statusOk} size={15} color="#636DEE" /></div>
                    }
                    {
                        !isStatus && <div className={s.time}>{changed && <MdEdit className={s.changed} size={14} />}{changed ? updatedAt.slice(11, 16) : createdAt.slice(11, 16)}</div>
                    }
                </div>
            </div>
        </li>
    )
}

export default MessageItem
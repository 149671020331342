import { FC } from "react";
import { IContextMenuContent } from "../../interfaces/context-menu.interface";

import s from './context_menu.module.scss'

interface ContextMenuProps {
    top: number
    left: number
    backgroundColor?: string
    isVisible: boolean
    contextRef: any
    content: IContextMenuContent[]
    clickItem: (slug: string) => void
}

const ContextMenu: FC<ContextMenuProps> = ({ top, left, backgroundColor = '#fff', isVisible, contextRef, content, clickItem }) => {
    return (
        <>
            {
                content && (
                    <div className={isVisible ? `${s.menu} ${s.active}` : s.menu} style={{ top, left, backgroundColor }} ref={contextRef}>
                        <ul className={s.list}>
                            {
                                content.map((item, index) => (
                                    <li className={s.item} onClick={() => clickItem(item.slug)} key={index}>
                                        <div className={s.ico}>
                                            {item.ico}
                                        </div>
                                        <div className={s.text}>
                                            {item.text}
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }
        </>
    )
}

export default ContextMenu
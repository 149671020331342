import React, { FC, useRef, useState } from "react"
import MessageItem from "./message_item/message_item"
import NoMessages from "../nomessages/nomessages"
import ContextMenu from "../contex_menu/context_menu"
import { RootState } from "../../redux/store"
import { useSelector } from "react-redux"
import { useContextMenu } from "../../hooks/use_context_menu"
import { messageContextMenu } from "../../constans/context_menus"
import { GroupType, MessageType } from "../../enums/messages.enum"
import { IUser } from "../../interfaces/users.interface"
import { IMessage } from "../../interfaces/messages.interface"
import { IContextMenuContent } from "../../interfaces/context-menu.interface"

import s from './messages.module.scss'

interface MessagesProps {
    messages: IMessage[]
    users: IUser[]
    userTyping: IUser
    clickContextMenuItem: (slugContextMenu: string, message: IMessage) => void
}

const Messages: FC<MessagesProps> = React.memo(({ messages, users, userTyping, clickContextMenuItem }) => {
    const profile = useSelector((state: RootState) => state.user.profile)
    const [excludeSlugContextItem, setExcludeSlugContextItem] = useState<string[]>([])
    const { x, y, isVisibleContextMenu, contextRef, openContextMenu, closeContextMenu } = useContextMenu()
    // Выбранное сообщение с помощью контекстного меню
    const messageSelect = useRef<IMessage>(null)

    const handleClickContextMenu = (e: React.MouseEvent, message: IMessage) => {
        messageSelect.current = message
        if (message.user_id !== profile.id) {
            setExcludeSlugContextItem(['edit'])
        } else {
            setExcludeSlugContextItem([])
        }
        openContextMenu(e)
    }

    const handleClickContextMenuItem = (slugContextMenu: string) => {
        clickContextMenuItem(slugContextMenu, messageSelect.current)
        closeContextMenu()
    }

    return (
        <div className={s.messages}>
            {
                messages.length ? (
                    <ul className={s.list}>
                        {
                            messages?.map(item => (
                                <MessageItem
                                    text={(item.data?.text)}
                                    fileName={item.data?.filename}
                                    type={item.type}
                                    status={item.status}
                                    isStatus={(item.user_id === profile.id) ? true : false}
                                    groupType={GroupType.PM}
                                    changed={item.changed}
                                    // user_id={item.user_id}
                                    createdAt={item.createdAt}
                                    updatedAt={item.updatedAt}
                                    backgroundColor={item.user_id === profile.id ? '#E9FECB' : '#fff'}
                                    user={users.find(user => user.id === item.user_id)}
                                    clickContextMenu={(e) => handleClickContextMenu(e, item)}
                                    key={item.message_id}
                                />
                            ))
                        }
                        <div className={s.typing}>
                            {
                                userTyping && userTyping.typeStatus && <span>{userTyping.surname} {userTyping.name} печатает...</span>
                            }
                        </div>
                    </ul>
                ) : (
                    <NoMessages />
                )
            }

            <ContextMenu
                top={y}
                left={x}
                isVisible={isVisibleContextMenu}
                contextRef={contextRef}
                content={messageContextMenu?.content.reduce((acc, item) => {
                    if (!excludeSlugContextItem.includes(item.slug)) acc.push(item)
                    return acc
                }, [])
                }
                // clickItem={(slug) => handleClickContextMenuItem(slug, Number(activeIndexRow), tableData[Number(activeIndexRow)])}
                clickItem={(slug) => handleClickContextMenuItem(slug)}
            />
        </div>
    )
})

export default Messages
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.find_find__NZnMW {
  display: flex;
  align-items: center;
  padding: 25px 20px 25px 15px;
  border: 1px solid #D0D0D0;
  border-bottom: none;
  width: 100%;
  box-sizing: border-box;
}
.find_findInputWrap__6NXfs {
  width: 100%;
}
.find_findIco__\\+dKlD {
  color: #aaa;
  margin-right: 10px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.find_findIco__\\+dKlD:hover {
  background-color: #D0D0D0;
  color: #000;
}
.find_findInput__8eUWL {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 30px 10px 20px;
  border: 1px solid #aaa;
  box-shadow: none;
  border-radius: 40px;
}

@media screen and (max-width: 1300px) {
  .find_find__NZnMW {
    padding: 25px 20px 25px 15px;
  }
  .find_findIco__\\+dKlD {
    margin-right: 10px;
    padding: 5px;
  }
  .find_findInput__8eUWL {
    font-size: 14px;
    padding: 7px 30px 7px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/find/find.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,4BAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;AACJ;AACI;EACI,WAAA;AACR;AAEI;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAAR;AACQ;EACI,yBAAA;EACA,WAAA;AACZ;AAGI;EACI,WAAA;EACA,eAAA;EACA,mBAAA;EACA,4BAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AADR;;AAKA;EACI;IACI,4BAAA;EAFN;EAGM;IACI,kBAAA;IACA,YAAA;EADV;EAIM;IACI,eAAA;IACA,0BAAA;EAFV;AACF","sourcesContent":[".find {\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 25px 20px 25px 15px;\r\n    border: 1px solid #D0D0D0;\r\n    border-bottom: none;\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n\r\n    &InputWrap {\r\n        width: 100%;\r\n    }\r\n\r\n    &Ico {\r\n        color: #aaa;\r\n        margin-right: 10px;\r\n        padding: 5px;\r\n        border-radius: 50%;\r\n        cursor: pointer;\r\n        &:hover{\r\n            background-color: #D0D0D0;\r\n            color: #000;\r\n        }\r\n    }\r\n\r\n    &Input {\r\n        width: 100%;\r\n        font-size: 16px;\r\n        font-weight: normal;\r\n        padding: 10px 30px 10px 20px;\r\n        border: 1px solid #aaa;\r\n        box-shadow: none;\r\n        border-radius: 40px;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 1300px) {\r\n    .find {\r\n        padding: 25px 20px 25px 15px;\r\n        &Ico {\r\n            margin-right: 10px;\r\n            padding: 5px;\r\n        }\r\n    \r\n        &Input {\r\n            font-size: 14px;\r\n            padding: 7px 30px 7px 20px;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"find": `find_find__NZnMW`,
	"findInputWrap": `find_findInputWrap__6NXfs`,
	"findIco": `find_findIco__+dKlD`,
	"findInput": `find_findInput__8eUWL`
};
export default ___CSS_LOADER_EXPORT___;

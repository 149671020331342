// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/img/background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Manrope", sans-serif;
  color: #000;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
li {
  display: block;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button:active,
button:focus {
  outline: none;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA;EACC,SAAA;EACA,UAAA;EACA,kCAAA;EACA,WAAA;AACD;;AAEA;EACC,iBAAA;EACA,cAAA;EAEA,yDAAA;EACA,4BAAA;EACA,sBAAA;AAAD;;AAGA;;;;;;;;;;EAUC,SAAA;AAAD;;AAGA;;EAEC,cAAA;EACA,SAAA;EACA,UAAA;AAAD;;AAGA;EACC,gBAAA;AAAD;;AAGA;EACC,qBAAA;AAAD;;AAGA;;EAEC,aAAA;EACA,SAAA;AAAD","sourcesContent":["body {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tfont-family: 'Manrope', sans-serif;\r\n\tcolor: #000;\r\n}\r\n\r\n.container {\r\n\tmax-width: 1800px;\r\n\tmargin: 0 auto;\r\n\t// padding: 0 10px;\r\n\tbackground-image: url(\"../../public/img/background.webp\");\r\n\tbackground-repeat: no-repeat;\r\n\tbackground-size: cover;\r\n}\r\n\r\nul,\r\nol,\r\nli,\r\np,\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6 {\r\n\tmargin: 0;\r\n}\r\n\r\nul,\r\nli {\r\n\tdisplay: block;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\n\r\nli {\r\n\tlist-style: none;\r\n}\r\n\r\na {\r\n\ttext-decoration: none;\r\n}\r\n\r\nbutton:active,\r\nbutton:focus {\r\n\toutline: none;\r\n\tborder: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { FC } from "react"
import { PiShareFat } from "react-icons/pi"
import { MdOutlineModeEdit } from "react-icons/md"
import { MdClose } from "react-icons/md"


import s from './above_message_input.module.scss'
import { MessageType } from "../../enums/messages.enum"

interface AboveMessageInputProps {
    type: 'answer' | 'edit'
    contactName: string
    messageType: MessageType
    text: string
    img?: string
    className?: string
    style?: React.CSSProperties
    clickClose: () => void
}

const AboveMessageInput: FC<AboveMessageInputProps> = ({ type, contactName, messageType, text, img, className, style, clickClose }) => {

    return (
        <div className={s.item} style={{ ...style }}>
            <div className={`${className} ${s.content}`}>
                {
                    type === 'answer' && <PiShareFat className={s.ico} size={25} color="#636dee" />
                }
                {
                    type === 'edit' && <MdOutlineModeEdit className={s.ico} size={20} color="#F71C57" />
                }
                <div className={type === "edit" ? `${s.info} ${s.edit}` : `${s.info} ${s.answer}`}>
                    {
                        type === 'answer' && <div className={type === "answer" ? `${s.top} ${s.answer}` : `${s.top} ${s.edit}`}>В ответ {contactName}</div>
                    }
                    {
                        type === 'edit' && <div className={type === "edit" ? `${s.top} ${s.edit}` : `${s.top} ${s.answer}`}>Редактирование</div>
                    }
                    {
                        messageType === MessageType.TEXT && <div className={s.bottom}>{text}</div>
                    }
                </div>
            </div>
            <MdClose className={s.close} size={20} onClick={clickClose} />
        </div>
    )
}

export default AboveMessageInput